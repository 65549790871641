.slider {
    background-color: #000;
    border-bottom: 1px solid $main;
    border-top: 1px solid $main;
    position: relative;

    @include md {
        display: none;
    }

    &__maintext {
        font-size: 4rem;
        text-transform: uppercase;
        position: absolute;
        top: 30%;
        left: 20%;
        font-weight: 700;
        color: $main;
        text-shadow: 0px 6px 12px #000000;
    }

    &__subtext {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: right;
        color: #fff;
        position: absolute;
        right: 10%;
        bottom: 20%;
        text-shadow: 0px 6px 12px #000000;
        font-weight: bold;
    }

    &__item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        min-height: 300px;
    }
}
