.bar {
    width: 1.6rem;
    display: flex;
    height: 1rem;
    flex-direction: column;
    cursor: pointer;
    transition: $ts;
    position: absolute;
    z-index: 99;
    right: 1rem;
    top: 1rem;
    display: none;
    @include md {
        display: block;
    }
    &__item {
        height: 5px;
        border-radius: $br6;
        background: $main;
        width: 100%;
        transition: $ts;
        position: absolute;

        &:first-child {
            top: 0;
        }

        &:last-child {
            top: 0.5rem;
        }

        &:nth-child(2) {
            top: 1rem;
        }
    }

    &._active {
        .bar__item {
            &:first-child {
                transform: rotate(-45deg);
                top: 8px;
            }
            &:last-child {
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
        }
    }
}

.menu-collapsed {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    transition: $ts;
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    will-change: visibility, background;
    .layout {
        width: 30%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }
    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        height: 100%;
        padding: 2rem 0;
        width: 70%;
        transform: translateX(-100%);
        background: #000;
        transition: $ts;
        will-change: transform;

        ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &._active {
        visibility: visible;
        background: rgba(0, 0, 0, 0.6);
        nav {
            transform: translateX(0);
        }
    }
}
