.stream {
    transition: $ts;
    overflow: hidden;

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
    }

    &__link {
        display: flex;
        color: $main;
        align-items: center;
        transition: $ts;
        animation: move 2s infinite ease;

        img {
            display: block;
            width: 2rem;
            height: 2rem;
        }

        span {
            display: block;
            margin-right: 1rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        &:hover {
            text-decoration: none;
            color: $main;
        }
    }
}

@keyframes move {
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
