.header {
    border-bottom: 1px solid $main;
    background: url(../images/bg.png);
    position: relative;
    box-shadow: inset $shadow;

    &__part2 {
        color: $main;
    }

    &__sticky {
        position: fixed;
        width: 100%;
        top: 0;
        transform: translateY(-100%);
        left: 0;
        right: 0;
        z-index: 99;
        transition: $ts;
        background: #fff;
        border-bottom: 1px solid $main;
        box-shadow: $shadow;

        @include md {
            display: none;
        }

        .header__list {
            max-width: 800px;
            margin: 0 auto;
            justify-content: center;
        }

        .header__link {
            padding: 0.5rem 1rem;
            font-size: 0.8rem;
            font-weight: bold;
            color: #5d5d5d;
            border-radius: 0;
        }
    }

    &__part1 {
        color: #fff;
    }

    &__title {
        font-size: 1.5rem;
        color: $main;
        padding: 0 1rem 1rem 1rem;
        text-align: center;
        position: relative;
        z-index: 1;
        text-shadow: 2px 1px 0 #191618;
        display: block;

        &:hover {
            text-decoration: none;
        }
    }

    &__list {
        list-style: none;
        display: flex;
        justify-content: space-between;

        @include md {
            text-align: center;
        }
    }

    &__nav {
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        @include md {
            display: none;
        }
    }

    &__burger {
        display: none;
        text-transform: uppercase;
        height: 100%;
        width: 100%;
        padding: 1rem 0;

        @include md {
            display: block;
        }
    }
    &__item {
        flex-shrink: 0;
    }

    &__link,
    &__lang-switcher {
        transition: $ts;
        color: $main;
        display: inline-block;
        padding: 1rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        @include md {
            font-size: 1.5rem;
            border-radius: 0;
            font-weight: bold;
        }

        &:hover,
        &._active {
            color: $darkblue;
            background-color: $main;
            text-decoration: none;
        }
    }

    &__language {
        .header__lang-switcher {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            @include md {
                font-weight: normal;
                font-size: 1rem;
            }
        }
        @include md {
            text-align: center;
            margin-bottom: 0;
        }
    }
}
