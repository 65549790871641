$md: 768px;
$lg: 992px;

@mixin md {
    @media (max-width: $md) {
        @content;
    }
}

@mixin lg {
    @media (max-width: $lg) {
        @content;
    }
}
