.dpi {
    text-align: center;
    &__text {
        padding: 0;
        margin: 0;
    }
    &__input {
        padding: 0.5rem 1rem;
        border: 2px solid #f9f9f9;
        border-radius: $br6;
        position: relative;
        display: inline-block;
        margin-bottom: 1rem;
        width: 200px;
        &:focus {
            outline: none;
        }
    }

    &__calculate {
        background-color: $main;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        color: #000;
        border-radius: $br6;
        cursor: pointer;
        border: 2px solid $main;
        transition: $ts;
        margin-bottom: 1rem;
        width: 200px;
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: lighten($main, 10%);
            border-color: lighten($main, 10%);
        }
    }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
