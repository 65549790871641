html,
body {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

body {
    color: $white;
    font-family: 'Montserrat', sans-serif;
    background-image: url(../images/bg2.png);
    background-color: #000;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

img {
    display: block;
}

svg {
    max-height: 1rem;
    display: inline-block;
    max-width: 2rem;
    transition: $ts;

    path {
        transition: $ts;
    }
}
