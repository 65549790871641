.bar {
  cursor: pointer;
  z-index: 99;
  flex-direction: column;
  width: 1.6rem;
  height: 1rem;
  transition: all .3s;
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media (width <= 768px) {
  .bar {
    display: block;
  }
}

.bar__item {
  background: #ffc044;
  border-radius: 6px;
  width: 100%;
  height: 5px;
  transition: all .3s;
  position: absolute;
}

.bar__item:first-child {
  top: 0;
}

.bar__item:last-child {
  top: .5rem;
}

.bar__item:nth-child(2) {
  top: 1rem;
}

.bar._active .bar__item:first-child {
  top: 8px;
  transform: rotate(-45deg);
}

.bar._active .bar__item:last-child {
  transform: rotate(45deg);
}

.bar._active .bar__item:nth-child(2) {
  opacity: 0;
}

.menu-collapsed {
  visibility: hidden;
  z-index: 9;
  will-change: visibility, background;
  background: #0000001a;
  width: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.menu-collapsed .layout {
  width: 30%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.menu-collapsed nav {
  text-align: center;
  will-change: transform;
  background: #000;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 100%;
  padding: 2rem 0;
  transition: all .3s;
  display: flex;
  transform: translateX(-100%);
}

.menu-collapsed nav ul {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.menu-collapsed._active {
  visibility: visible;
  background: #0009;
}

.menu-collapsed._active nav {
  transform: translateX(0);
}

.dpi {
  text-align: center;
}

.dpi__text {
  margin: 0;
  padding: 0;
}

.dpi__input {
  border: 2px solid #f9f9f9;
  border-radius: 6px;
  width: 200px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: inline-block;
  position: relative;
}

.dpi__input:focus {
  outline: none;
}

.dpi__calculate {
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  background-color: #ffc044;
  border: 2px solid #ffc044;
  border-radius: 6px;
  width: 200px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  transition: all .3s;
}

.dpi__calculate:focus {
  outline: none;
}

.dpi__calculate:hover {
  background-color: #ffd177;
  border-color: #ffd177;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.slider {
  background-color: #000;
  border-top: 1px solid #ffc044;
  border-bottom: 1px solid #ffc044;
  position: relative;
}

@media (width <= 768px) {
  .slider {
    display: none;
  }
}

.slider__maintext {
  text-transform: uppercase;
  color: #ffc044;
  text-shadow: 0 6px 12px #000;
  font-size: 4rem;
  font-weight: 700;
  position: absolute;
  top: 30%;
  left: 20%;
}

.slider__subtext {
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  text-shadow: 0 6px 12px #000;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  bottom: 20%;
  right: 10%;
}

.slider__item {
  justify-content: space-around;
  align-items: center;
  min-height: 300px;
  display: flex;
  position: relative;
}

.stream {
  transition: all .3s;
  overflow: hidden;
}

.stream__inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.stream__link {
  color: #ffc044;
  align-items: center;
  transition: all .3s;
  animation: 2s infinite move;
  display: flex;
}

.stream__link img {
  width: 2rem;
  height: 2rem;
  display: block;
}

.stream__link span {
  text-transform: uppercase;
  margin-right: 1rem;
  font-weight: bold;
  display: block;
}

.stream__link:hover {
  color: #ffc044;
  text-decoration: none;
}

@keyframes move {
  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  color: #fffcf2;
  background-color: #000;
  background-image: url("bg2.13ef6151.png");
  font-family: Montserrat, sans-serif;
}

* {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

svg {
  max-width: 2rem;
  max-height: 1rem;
  transition: all .3s;
  display: inline-block;
}

svg path {
  transition: all .3s;
}

.header {
  background: url("bg.25d79334.png");
  border-bottom: 1px solid #ffc044;
  position: relative;
  box-shadow: inset 0 1px 39px #0000006e;
}

.header__part2 {
  color: #ffc044;
}

.header__sticky {
  z-index: 99;
  background: #fff;
  border-bottom: 1px solid #ffc044;
  width: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  box-shadow: 0 1px 39px #0000006e;
}

@media (width <= 768px) {
  .header__sticky {
    display: none;
  }
}

.header__sticky .header__list {
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

.header__sticky .header__link {
  color: #5d5d5d;
  border-radius: 0;
  padding: .5rem 1rem;
  font-size: .8rem;
  font-weight: bold;
}

.header__part1 {
  color: #fff;
}

.header__title {
  color: #ffc044;
  text-align: center;
  z-index: 1;
  text-shadow: 2px 1px #191618;
  padding: 0 1rem 1rem;
  font-size: 1.5rem;
  display: block;
  position: relative;
}

.header__title:hover {
  text-decoration: none;
}

.header__list {
  justify-content: space-between;
  list-style: none;
  display: flex;
}

@media (width <= 768px) {
  .header__list {
    text-align: center;
  }
}

.header__nav {
  text-transform: uppercase;
  justify-content: center;
  display: flex;
}

@media (width <= 768px) {
  .header__nav {
    display: none;
  }
}

.header__burger {
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  display: none;
}

@media (width <= 768px) {
  .header__burger {
    display: block;
  }
}

.header__item {
  flex-shrink: 0;
}

.header__link, .header__lang-switcher {
  color: #ffc044;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  transition: all .3s;
  display: inline-block;
}

@media (width <= 768px) {
  .header__link, .header__lang-switcher {
    border-radius: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.header__link:hover, .header__link._active, .header__lang-switcher:hover, .header__lang-switcher._active {
  color: #242331;
  background-color: #ffc044;
  text-decoration: none;
}

.header__language .header__lang-switcher {
  border-radius: 0 0 10px 10px;
}

@media (width <= 768px) {
  .header__language .header__lang-switcher {
    font-size: 1rem;
    font-weight: normal;
  }

  .header__language {
    text-align: center;
    margin-bottom: 0;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.container a:hover {
  text-decoration: none;
}

@media (width <= 768px) {
  .container {
    max-width: 100%;
    padding: 0 1rem;
  }
}

.content {
  padding-bottom: 1rem;
}

.content__title {
  text-align: center;
  text-transform: uppercase;
  color: #fffcf2;
  padding: 1rem 0;
  font-size: 2rem;
  position: relative;
}

@media (width <= 768px) {
  .content__title {
    font-size: 1rem;
  }
}

.content__name {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.content__text {
  letter-spacing: .4px;
  font-size: 1rem;
  line-height: 1.5;
}

.content__subtitle {
  margin-bottom: 1rem;
  font-weight: 600;
}

@media (width <= 768px) {
  .content__subtitle {
    font-size: 1rem;
  }
}

.content__item {
  color: #fffcf2;
  background-color: #0c0c0c;
  border-left: 1rem solid #ffc044;
  border-top-right-radius: 20px;
  margin-bottom: 1rem;
  padding: 1rem 1rem 2rem;
  transition: all .3s;
  position: relative;
  box-shadow: 0 0 3px #000000bf;
}

.content__item:hover {
  background-color: #000;
  border-color: #e96d00;
}

@media (width <= 768px) {
  .content__item {
    border-left: .5rem solid #ffc044;
    padding: 1rem;
  }
}

.content__link {
  color: #ffc044;
  text-transform: uppercase;
  margin-top: .5rem;
  font-weight: bold;
  transition: all .3s;
  display: block;
}

.content__link:hover {
  color: #fffcf2;
}

.content__image {
  justify-content: center;
  margin: 1rem auto;
  padding-bottom: 1rem;
}

.content__image img {
  border: 6px solid #ffc044;
  border-radius: 6px;
  max-width: 100%;
  max-height: 600px;
  margin: 0 auto;
}

.content__photo {
  text-align: center;
  margin: 1rem 0;
}

.content__photo img {
  border: 6px solid #ffc044;
  border-radius: 50%;
}

.content__listitem {
  margin-top: .5rem;
}

.content__sources {
  margin-bottom: .5rem;
}

.message__text {
  color: #fffcf2;
  text-align: center;
  background-color: #0c0c0c;
  margin-bottom: 1rem;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 3px #000000bf;
}

.swiper-horizontal > .swiper-pagination.slider-pagination {
  bottom: -4px;
}

.swiper-horizontal > .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffc044;
}

.swiper-horizontal > .swiper-pagination .swiper-pagination-bullet {
  opacity: .5;
  background: #fff;
}

.content__images .swiper-button-next, .content__images .swiper-button-prev {
  display: none;
}

.swiper-horizontal .swiper-button-next, .swiper-horizontal .swiper-button-prev {
  color: #fff;
  display: block;
}

.footer {
  text-align: center;
  background: url("bg.25d79334.png");
  border-top: 1px solid #ffc044;
  padding: 1rem 0;
}

.footer__icon {
  width: 1rem;
  height: 1rem;
}

.footer__link {
  color: #ffc044;
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 600;
  transition: all .3s;
}

.footer__link:hover {
  color: #fffcf2;
}

.footer__link i {
  margin-right: 1rem;
}

.footer__item {
  display: inline-block;
}

.footer__list {
  flex-flow: row;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
}

@media (width <= 768px) {
  .footer__list {
    flex-direction: column;
  }
}

.footer__inner {
  flex-direction: column;
  display: flex;
}

.footer__socials {
  margin-bottom: 1rem;
  padding: 1rem 0;
}

.footer__social {
  color: #ffc044;
  border: 1px solid #ffc044;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  transition: all .3s;
  display: inline-flex;
}

.footer__social + .footer__social {
  margin-left: 1rem;
}

.footer__social:hover {
  transform: translateY(-4px);
}

.footer__subtitle {
  text-transform: uppercase;
}

@media (width <= 768px) {
  .footer__subtitle {
    margin-top: 1rem;
  }
}

.footer__mail {
  color: #ffc044;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  transition: all .3s;
  display: flex;
}

.footer__mail:hover {
  color: #fff;
}

.footer__mail:hover path {
  fill: #fff;
}

.footer__text {
  color: #8a939b;
  font-size: .8rem;
}
/*# sourceMappingURL=bios.895330e5.css.map */
