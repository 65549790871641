.footer {
    text-align: center;
    padding: 1rem 0;
    border-top: 1px solid $main;
    background: url(../images/bg.png);

    &__icon {
        width: 1rem;
        height: 1rem;
    }
    &__link {
        color: $main;
        font-size: 0.8rem;
        transition: $ts;
        text-transform: uppercase;
        font-weight: 600;

        &:hover {
            color: $white;
        }

        i {
            margin-right: 1rem;
        }
    }

    &__item {
        display: inline-block;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        max-width: 800px;
        margin: 0 auto;

        @include md {
            flex-direction: column;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }
    &__socials {
        padding: 1rem 0;
        margin-bottom: 1rem;
    }
    &__social {
        color: #ffc044;
        transition: $ts;

        border: 1px solid #ffc044;
        border-radius: 50%;
        line-height: 2rem;
        height: 2rem;
        width: 2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        & + & {
            margin-left: 1rem;
        }

        &:hover {
            transform: translateY(-4px);
        }
    }
    &__subtitle {
        text-transform: uppercase;

        @include md {
            margin-top: 1rem;
        }
    }

    &__mail {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $main;
        transition: $ts;
        font-size: 1rem;
        &:hover {
            color: #fff;
            path {
                fill: #fff;
            }
        }
    }

    &__text {
        color: #8a939b;
        font-size: 0.8rem;
    }
}
