.container {
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 1200px;

    a {
        &:hover {
            text-decoration: none;
        }
    }

    @include md {
        max-width: 100%;
        padding: 0 1rem;
    }
}
.content {
    padding-bottom: 1rem;

    &__title {
        text-align: center;
        padding: 1rem 0;
        font-size: 2rem;
        position: relative;
        text-transform: uppercase;
        color: $white;
        @include md {
            font-size: 1rem;
        }
    }

    &__name {
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    &__text {
        letter-spacing: 0.4;
        line-height: 1.5;
        font-size: 1rem;
    }

    &__subtitle {
        font-weight: 600;
        margin-bottom: 1rem;

        @include md {
            font-size: 1rem;
        }
    }

    &__item {
        background-color: darken($gray, 5%);
        padding: 1rem;
        margin-bottom: 1rem;
        position: relative;
        transition: $ts;
        border-left: 1rem solid $main;
        border-top-right-radius: 20px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
        color: $white;
        padding-bottom: 2rem;

        &:hover {
            background-color: darken($gray, 10%);
            border-color: $secondary;
        }

        @include md {
            padding: 1rem;
            border-left: 0.5rem solid $main;
        }
    }

    &__link {
        display: block;
        color: $main;
        transition: $ts;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 0.5rem;

        &:hover {
            color: $white;
        }
    }

    &__image {
        margin: 1rem auto;
        justify-content: center;
        padding-bottom: 1rem;
        img {
            border: $br6 solid $main;
            border-radius: $br6;
            max-width: 100%;
            margin: 0 auto;
            max-height: 600px;
        }
    }

    &__photo {
        text-align: center;
        margin: 1rem 0;

        img {
            border: $br6 solid $main;
            border-radius: 50%;
        }
    }

    &__listitem {
        margin-top: 0.5rem;
    }

    &__sources {
        margin-bottom: 0.5rem;
    }
}

.message__text {
    background-color: #0c0c0c;
    padding: 2rem;
    margin-bottom: 1rem;
    position: relative;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);
    color: #fffcf2;
    text-align: center;
}

.swiper-horizontal > .swiper-pagination.slider-pagination {
    bottom: -4px;
}

.swiper-horizontal
    > .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $main;
    opacity: 1;
}

.swiper-horizontal > .swiper-pagination .swiper-pagination-bullet {
    background: white;
    opacity: 0.5;
}

.content__images {
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}

.swiper-horizontal {
    .swiper-button-next,
    .swiper-button-prev {
        color: white;
        display: block;
    }
}
